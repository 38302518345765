<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card">
        <h4 class="text-center"><strong>DATOS DE USUARIO</strong></h4>
        <div class="field">
          <p>
            <strong>ID USUARIO:</strong> <span>{{ user.id }}</span>
          </p>
        </div>
        <div class="field">
          <p>
            <strong>NOMBRE COMPLETO:</strong> <span>{{ user.name }}</span>
          </p>
        </div>
        <div class="field">
          <p>
            <strong>CORREO ELECTR&Oacute;NICO:</strong> <span>{{ user.email }}</span>
          </p>
        </div>
        <div class="field">
          <p>
            <strong>FECHA REGISTRO:</strong> <span>{{ formatFecha(user.created_at) }}</span>
          </p>
        </div>
        <div class="field">
          <p>
            <strong>FECHA ACTUALIZACI&Oacute;N: </strong>
            <span> {{ formatFecha(user.updated_at) }}</span>
          </p>
        </div>
      </div>
      <div class="card">
        <h4 class="text-center"><strong>PERMISOS ASIGNADOS</strong></h4>
        <PermissionsFrom :permisos-seleccionados="user.permissions" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAuth } from "@/stores";
import PermissionsFrom from "@/module/roles/PermissionsForm.vue";
import UserService from "@/service/UserService";

export default {
  components: {
    PermissionsFrom,
  },
  data() {
    return {
      user: {},
    };
  },
  userService: null,
  authService: null,
  created() {
    this.authService = useAuth();
    this.userService = new UserService();
  },
  mounted() {
    this.user = { ...this.authService.user };
    this.userService.ListarPermisosUsuarios().then((data) => {
      this.user.permissions = data.permisos;
    });
  },
  methods: {
    formatFecha(fecha) {
      return new Date(fecha).toLocaleString();
    },
  },
};
</script>